"use client";

import React, { FC } from "react";

import { SeoExtended, SeoMetaTagsProvider } from "./helpers";

export const SeoMetaTags: FC<SeoExtended> = ({ jsonLd, extraProps }) => {
  if (!jsonLd) {
    return null;
  }

  return (
    <SeoMetaTagsProvider value={extraProps ?? {}}>
      {jsonLd as React.ReactNode}
    </SeoMetaTagsProvider>
  );
};
