"use client";
import { CSSProperties } from "react";

import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";

import { TypographyBodyClass } from "~/theme/typography";
import { getFormatMedia } from "~/theme/utils";

import { ARTICLE_PAGE_RICH_TEXT_BLOCK_CLASSES } from "./constants";

export const ArticlePageRichTextBlockContainer = styled(Container)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    [`& .${ARTICLE_PAGE_RICH_TEXT_BLOCK_CLASSES.richText}`]: {
      marginBottom: theme.spacing(7),
      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(8),
      },
      "& h1": {
        textTransform: "inherit",
        marginBottom: theme.spacing(3),
        [formatMedia.BREAKPOINT_TABLET]: {
          textTransform: "inherit",
          marginBottom: theme.spacing(4),
        },
      },
      "& h2": {
        ...theme.typography.h4,
        textTransform: "inherit",
        marginBottom: theme.spacing(3),
        [formatMedia.BREAKPOINT_TABLET]: {
          ...theme.typography.h3,
          textTransform: "inherit",
          marginBottom: theme.spacing(4),
        },
      },
      "& h3": {
        marginBottom: theme.spacing(3),
        ...theme.typography.h5,
        textTransform: "inherit",
        [formatMedia.BREAKPOINT_TABLET]: {
          marginBottom: theme.spacing(4),
          ...theme.typography.h4,
          textTransform: "inherit",
        },
      },
      "& h4": {
        ...theme.typography.h6,
        textTransform: "inherit",
        marginBottom: theme.spacing(3),
        [formatMedia.BREAKPOINT_TABLET]: {
          ...theme.typography.h5,
          textTransform: "inherit",
          marginBottom: theme.spacing(4),
        },
      },
      "& h5": {
        textTransform: "inherit",
      },
      "& p, & ul, & ol": {
        margin: 0,
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.articleSmall}`
        ] as CSSProperties),
        [formatMedia.BREAKPOINT_TABLET]: {
          ...(theme.typography.body1[
            `&.${TypographyBodyClass.articleLarge}`
          ] as CSSProperties),
        },
      },
      "& p": {
        marginBottom: theme.spacing(4),
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
      "& a": {
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.articleSmallLink}`
        ] as CSSProperties),
        color: "inherit",
        [formatMedia.BREAKPOINT_TABLET]: {
          ...(theme.typography.body1[
            `&.${TypographyBodyClass.articleLargeLink}`
          ] as CSSProperties),
        },
      },
      "& ul": {
        listStyle: "none",
      },
      "& ul, & ol": {
        paddingLeft: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        gap: 13,
        marginTop: 15,
        marginBottom: theme.spacing(4),
        [formatMedia.BREAKPOINT_TABLET]: {
          gap: 15,
        },
      },
      "& ul li": {
        position: "relative",
        "&::before": {
          content: "''",
          position: "absolute",
          width: 8,
          height: 8,
          display: "block",
          border: "2px solid #000",
          borderRadius: "50%",
          top: 9,
          left: -24,
          [formatMedia.BREAKPOINT_TABLET]: {
            top: 12,
          },
        },
      },
      "& > :last-child": {
        marginBottom: 0,
      },
    },
  };
});
