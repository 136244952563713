"use client";
import React, { FC, useEffect, useMemo } from "react";

import { useParams, usePathname } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { GCPProps } from "~/bff/components/GCP";
import { DataLayerData, EventsNames } from "~/constants/data-layer";
import { Variables } from "~/constants/request";
import { useVariable } from "~/context/variables/hooks/use-variable";
import { getCountryCode } from "~/helpers/country/get-country-code";
import { getPageCategoriesTitles } from "~/helpers/get-page-categories-titles";
import { pushDataLayer } from "~/helpers/push-data-layer";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { getJsonLdForBreadcrumbs } from "~/utils/jsonLd/breadcrumbs";

import { JsonLd } from "../json-ld/component";
import { SeoMetaTags } from "../seo-meta-tags/component";

import { GspPageContainer } from "./styled";

export const GcpPage: FC<GCPProps> = ({
  children,
  seo,
  breadcrumbs,
  testAutomationId = "gcp",
}) => {
  const { locale } = useParams<GlobalPathParams>();
  const pathname = usePathname();
  const pageLocale = locale;
  const urlPrefix = useVariable<string>(Variables.URL_PREFIX);
  const hideTopPadding = useVariable<boolean>(Variables.HIDE_TOP_SPACING_ON_GCP);
  const seoMetaTagsProps = useMemo(
    () => ({ seoUrlCanonical: { pageDomain: urlPrefix } }),
    [urlPrefix],
  );
  const breadcrumbJsonLd = useMemo(() => {
    const ltrBreadcrumbsSequence = breadcrumbs?.slice().reverse();
    return getJsonLdForBreadcrumbs(ltrBreadcrumbsSequence, pageLocale);
  }, [breadcrumbs, pageLocale]);

  useEffect(() => {
    const country = getCountryCode(pageLocale);
    const { pageCategory, pageSubCategory, pageSubSubCategory } =
      getPageCategoriesTitles(breadcrumbs, pathname);

    const dataForGA = {
      [DataLayerData.event]: EventsNames.content,
      [DataLayerData.siteCountry]: country,
      [DataLayerData.pageCategory]: pageCategory,
      [DataLayerData.pageSubCategory]: pageSubCategory,
      [DataLayerData.pageSubSubCategory]: pageSubSubCategory,
    };
    pushDataLayer(dataForGA);
  }, [breadcrumbs, pageLocale, pathname]);

  return (
    <GspPageContainer
      sx={(theme) => ({
        [theme.breakpoints.up("md")]: {
          paddingTop: hideTopPadding ? 0 : theme.spacing(4),
        },
      })}
      {...getTestAutomationProps(testAutomationId)}
    >
      {breadcrumbs && <JsonLd data={breadcrumbJsonLd} />}
      {seo && <SeoMetaTags {...seo} extraProps={seoMetaTagsProps} />}
      {children as React.ReactNode}
    </GspPageContainer>
  );
};
