"use client";
import React from "react";

import Grid from "@mui/material/Grid";

import { ArticlePageRichTextBlockProps } from "~/bff/components/ArticlePageRichTextBlock";
import { ARTICLE_PAGE_RICH_TEXT_BLOCK_CLASSES } from "~/components/article-page-rich-text-block/constants";
import { Markdown } from "~/components/markdown/component";
import { getTestAutomationProps } from "~/helpers/test-automation-props";

import { ArticlePageRichTextBlockContainer } from "./styled";

export const ArticlePageRichTextBlock: React.FC<ArticlePageRichTextBlockProps> = ({
  heading,
  text,
  meta,
  testAutomationId = "article-page-rich-text-block",
}) => {
  const idValue = `${testAutomationId}-${meta?.deliveryId}`;
  return (
    <ArticlePageRichTextBlockContainer
      {...getTestAutomationProps(testAutomationId)}
      id={idValue}
    >
      <Grid container justifyContent="center">
        <Grid item lg={6} md={8} xs={12}>
          {heading && (
            <Markdown
              className={ARTICLE_PAGE_RICH_TEXT_BLOCK_CLASSES.richText}
              text={heading}
            />
          )}
          {text && (
            <Markdown
              className={ARTICLE_PAGE_RICH_TEXT_BLOCK_CLASSES.richText}
              text={text}
            />
          )}
        </Grid>
      </Grid>
    </ArticlePageRichTextBlockContainer>
  );
};
