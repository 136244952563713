import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { COLOR_WHITE } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { GIFT_CARD_BALANCE_CHECKER_CLASSES } from "./constants";

export const GiftCardBalanceCheckerContainer = styled(Box)(({ theme }) => {
  const { BREAKPOINT_NARROW_PHONE, BREAKPOINT_TABLET, BREAKPOINT_WIDE_DESKTOP } =
    getFormatMedia(theme);

  return {
    background: COLOR_WHITE,
    [`& .${GIFT_CARD_BALANCE_CHECKER_CLASSES.title}`]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      ...theme.typography.h3,
      [BREAKPOINT_TABLET]: {
        ...theme.typography.h2,
      },
    },
    [`& .${GIFT_CARD_BALANCE_CHECKER_CLASSES.iframe}`]: {
      height: theme.spacing(65),
      width: "100%",
      border: "none",
      paddingLeft: 0,
      paddingRight: 0,
      [BREAKPOINT_NARROW_PHONE]: {
        minHeight: 710,
      },
      [theme.breakpoints.up(701)]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        minHeight: 780,
      },
      [theme.breakpoints.up(1033)]: {
        minHeight: 520,
      },
      [theme.breakpoints.up(1065)]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
      [BREAKPOINT_WIDE_DESKTOP]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
    },
  };
});
