import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { getFormatMedia } from "~/theme/utils";

export const GspPageContainer = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    maxWidth: "1920px",
    margin: "auto",
    '& [data-gcp-element="hero-banner"]': {
      marginBottom: theme.spacing(6),
      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(7.5),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(14.5),
      },
    },

    '& [data-gcp-element="editorial-content-video"]': {
      marginBottom: theme.spacing(6.375),
      [formatMedia.BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(9),
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(16),
      },

      "& video": {
        maxWidth: "100%",
        display: "block",
        margin: "0 auto",
        [formatMedia.BREAKPOINT_TABLET]: {
          maxWidth: "75vw",
        },
        [formatMedia.BREAKPOINT_DESKTOP]: {
          maxWidth: "60vw",
        },
      },
    },
  };
});
