import dynamic from "next/dynamic";

import { ArticlePage as ArticlePageType } from "~/bff/components/ArticlePage";
import { ArticleTile as ArticleTileType } from "~/bff/components/ArticleTile";
import {
  BffComponentType as Component,
  ComponentsConfig,
} from "~/bff/ComponentsConfig";
import { ArticlePageRichTextBlock } from "~/components/article-page-rich-text-block/component";
import { BrowseCollectionBanner } from "~/components/browse-collection-banner/component";
import { ClickAndCollectHeroBanner } from "~/components/click-and-collect-hero-banner/component";
import { GcpPage } from "~/components/gcp-page/component";
import { GiftCardBalanceChecker } from "~/components/gift-card-balance-checker/component";
import { HeroBanner } from "~/components/hero-banner/component";
import { HeroBannerCarousel } from "~/components/hero-banner/components/hero-banner-carousel/component";
import { HeroBannerCarouselItem } from "~/components/hero-banner/components/hero-banner-carousel-item/component";
import { HeroBannerCategory } from "~/components/hero-banner-category/component";
import { importComponentByName } from "~/helpers/import-component-by-name";

const ArticlePage = dynamic(async () =>
  importComponentByName(
    import("~/components/article-page/component"),
    "ArticlePage",
  ),
);

const Primania = dynamic(async () =>
  importComponentByName(import("~/components/primania/component"), "Primania"),
);

const ContactUsPage = dynamic(async () =>
  importComponentByName(
    import("~/components/contact-us-page/component"),
    "ContactUsPage",
  ),
);
const CookiePolicyPage = dynamic(async () =>
  importComponentByName(
    import("~/components/cookie-policy-page/component"),
    "CookiePolicyPage",
  ),
);

const CompetitionLandingPage = dynamic(async () =>
  importComponentByName(
    import("~/components/competition-landing-page/component"),
    "CompetitionLandingPage",
  ),
);

const ClickAndCollectCategoryCards = dynamic(async () =>
  importComponentByName(
    import("~/components/click-and-collect-category-cards/component"),
    "ClickAndCollectCategoryCards",
  ),
);

const LatestArticlesCarousel = dynamic(async () =>
  importComponentByName(
    import("~/components/latest-articles-carousel/component"),
    "LatestArticlesCarousel",
  ),
);

const ArticleTile = dynamic(async () =>
  importComponentByName(
    import("~/components/article-tile/component"),
    "ArticleTile",
  ),
);

const ArticleCards = dynamic(async () =>
  importComponentByName(
    import("~/components/article-cards/component"),
    "ArticleCards",
  ),
);

const RelatedArticleTile = dynamic(async () =>
  importComponentByName(
    import("~/components/related-article-tile/component"),
    "RelatedArticleTile",
  ),
);

const NavItemComponent = dynamic(async () =>
  importComponentByName(
    import(
      "~/components/home-page-categories/components/nav-item-component/component"
    ),
    "NavItemComponent",
  ),
);

const EditorialContentProductGroup = dynamic(async () =>
  importComponentByName(
    import("~/components/editorial-content-product-group/component"),
    "EditorialContentProductGroup",
  ),
);

const EditorialContentVideo = dynamic(async () =>
  importComponentByName(
    import("~/components/editorial-content-video/component"),
    "EditorialContentVideo",
  ),
);

const ArticlePageGenericButton = dynamic(async () =>
  importComponentByName(
    import("~/components/article-page-generic-button/component"),
    "ArticlePageGenericButton",
  ),
);

const ArticleProductCard = dynamic(async () =>
  importComponentByName(
    import("~/components/article-product-card/component"),
    "ArticleProductCard",
  ),
);

const ProductCarousel = dynamic(async () =>
  importComponentByName(
    import("~/components/product-carousel/component"),
    "ProductCarousel",
  ),
);

const ArticleImageComponent = dynamic(async () =>
  importComponentByName(
    import("~/components/article-image-component/component"),
    "ArticleImageComponent",
  ),
);

const Newsletter = dynamic(async () =>
  importComponentByName(import("~/components/newsletter/component"), "Newsletter"),
);

const AuthorComponent = dynamic(async () =>
  importComponentByName(
    import("~/components/author-component/component"),
    "AuthorComponent",
  ),
);

const ArticlePageThemeLink = dynamic(async () =>
  importComponentByName(
    import("~/components/hero-block/components/theme-link/component"),
    "ThemeLink",
  ),
);

const HeroImageProductCarousel = dynamic(async () =>
  importComponentByName(
    import("~/components/hero-image-product-carousel/component"),
    "HeroImageProductCarousel",
  ),
);

const ClickAndCollectAccordion = dynamic(async () =>
  importComponentByName(
    import("~/components/click-and-collect-accordion/component"),
    "ClickAndCollectAccordion",
  ),
);

const GcpPageAuthorComponent = dynamic(async () =>
  importComponentByName(
    import("~/components/gcp-page-author-component/component"),
    "GcpPageAuthorComponent",
  ),
);

const GcpSizeGuide = dynamic(async () =>
  importComponentByName(
    import("~/components/gcp-size-guide/component"),
    "GcpSizeGuide",
  ),
);

export const gcpPageConfig: ComponentsConfig = {
  [Component.GCP]: {
    component: GcpPage,
    overrides: {
      [Component.AUTHOR_COMPONENT]: {
        component: GcpPageAuthorComponent,
      },
    },
  },
  [Component.COOKIE_POLICY_PAGE]: {
    component: CookiePolicyPage,
  },
  [Component.COMPETITION_LANDING_PAGE]: {
    component: CompetitionLandingPage,
  },
  [Component.ARTICLE_PAGE]: {
    component: ArticlePage,
    overrides: {
      [Component.THEME_NAV_ITEM]: {
        component: ArticlePageThemeLink,
      },
    },
  },
  [Component.CLICK_COLLECT_CATEGORY_CARDS]: {
    component: ClickAndCollectCategoryCards,
    overrides: {
      [Component.NAV_ITEM]: {
        component: NavItemComponent,
      },
    },
  },
  [Component.LATEST_ARTICLES_CAROUSEL]: {
    component: LatestArticlesCarousel,
    overrides: {
      [Component.ARTICLE_PAGE]: {
        component: ArticleTile as ArticlePageType,
      },
      // ArticleTile is used for backward compatibility
      [Component.ARTICLE_TILE]: {
        component: ArticleTile as ArticleTileType,
      },
    },
  },
  [Component.RELATED_LATEST_ARTICLES_CAROUSEL]: {
    component: LatestArticlesCarousel,
    overrides: {
      [Component.ARTICLE_PAGE]: {
        component: RelatedArticleTile as ArticlePageType,
      },
    },
  },
  [Component.RELATED_ARTICLE_CARDS]: {
    component: ArticleCards,
    overrides: {
      [Component.ARTICLE_PAGE]: {
        component: RelatedArticleTile as ArticlePageType,
      },
    },
  },
  [Component.ARTICLE_PAGE_RICH_TEXT_BLOCK]: {
    component: ArticlePageRichTextBlock,
  },
  [Component.HERO_BANNER]: {
    component: HeroBanner,
  },
  [Component.HERO_BANNER_CAROUSEL]: {
    component: HeroBannerCarousel,
  },
  [Component.HERO_BANNER_CAROUSEL_ITEM]: {
    component: HeroBannerCarouselItem,
  },
  [Component.HERO_BANNER_CATEGORY]: {
    component: HeroBannerCategory,
  },
  [Component.HERO_IMAGE_PRODUCT_CAROUSEL]: {
    component: HeroImageProductCarousel,
  },
  [Component.BROWSE_COLLECTION_BANNER]: {
    component: BrowseCollectionBanner,
  },
  [Component.ARTICLE_CARDS]: {
    component: ArticleCards,
    overrides: {
      [Component.ARTICLE_PAGE]: {
        component: ArticleTile as ArticlePageType,
      },
      // ArticleTile is used for backward compatibility
      [Component.ARTICLE_TILE]: {
        component: ArticleTile as ArticleTileType,
      },
    },
  },
  [Component.EDITORIAL_CONTENT_PRODUCT_GROUP]: {
    component: EditorialContentProductGroup,
    // TODO: It's technical debt, we have availability to
    //  implement component without this parameter.
    stopChildrenPreparation: true,
  },
  [Component.EDITORIAL_CONTENT_VIDEO]: {
    component: EditorialContentVideo,
  },
  [Component.ARTICLE_TILE]: {
    component: ArticleTile as ArticleTileType,
  },
  [Component.ARTICLE_PAGE_GENERIC_BUTTON]: {
    component: ArticlePageGenericButton,
  },
  [Component.CLICK_COLLECT_ACCORDION]: {
    component: ClickAndCollectAccordion,
  },
  [Component.CLICK_COLLECT_HERO_BANNER]: {
    component: ClickAndCollectHeroBanner,
  },
  [Component.GIFT_CARD_BALANCE_CHECKER_COMPONENT]: {
    component: GiftCardBalanceChecker,
  },
  [Component.PRIMANIA_COMPONENT]: {
    component: Primania,
  },
  [Component.CONTACT_US]: {
    component: ContactUsPage,
  },
  [Component.ARTICLE_PAGE_PRODUCT_CARD]: {
    component: ArticleProductCard,
  },
  [Component.ARTICLE_PAGE_PRODUCT_CAROUSEL]: {
    component: ProductCarousel,
  },
  [Component.ARTICLE_PAGE_IMAGE_CONTAINER]: {
    component: ArticleImageComponent,
  },
  [Component.NEWSLETTER]: {
    component: Newsletter,
    // TODO: It's technical debt, we have availability to
    //  implement component without this parameter.
    stopChildrenPreparation: true,
  },
  [Component.AUTHOR_COMPONENT]: {
    component: AuthorComponent,
  },
  [Component.SIZE_GUIDE]: {
    component: GcpSizeGuide,
  },
};
