import { LOCALES } from "~/constants/i18n";

export const giftCardCheckerCountryIframeUrl = {
  [LOCALES["en-gb"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.uk",
  [LOCALES["fr-be"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.be?lng=fr",
  [LOCALES["nl-be"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.be?lng=nl",
  [LOCALES["cs-cz"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.cz",
  [LOCALES["de-de"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.de",
  [LOCALES["es-es"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.es",
  [LOCALES["fr-fr"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.fr",
  [LOCALES["en-ie"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.ie",
  [LOCALES["it-it"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.it",
  [LOCALES["nl-nl"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.nl",
  [LOCALES["pl-pl"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.pl",
  [LOCALES["pt-pt"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.pt",
  [LOCALES["sl-si"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.sl",
  [LOCALES["en-us"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.us",
  [LOCALES["de-at"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.at",
  [LOCALES["ro-ro"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.ro",
  [LOCALES["sk-sk"]]:
    "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.sk",
  // TODO: Do not work yet. Consult with this document: https://team-1626860771808.atlassian.net/wiki/spaces/OSCAR/pages/99451840/Gift+Card+Balance+Checker+Iframe+URLs+for+territory+rollout
  [LOCALES["es-us"]]: "",
  [LOCALES["hu-hu"]]: "",
  [LOCALES["el-gr"]]: "",
  // [LOCALES["hu-hu"]]:
  //   "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.hu",
  // [LOCALES["el-gr"]]:
  //   "https://wbiprod.storedvalue.com/wbir/clients/primarkgiftcards.gr",
};

export const GIFT_CARD_BALANCE_CHECKER_PREFIX = "GiftCardBalanceChecker";

export const GIFT_CARD_BALANCE_CHECKER_CLASSES = {
  title: `${GIFT_CARD_BALANCE_CHECKER_PREFIX}-title`,
  iframe: `${GIFT_CARD_BALANCE_CHECKER_PREFIX}-iframe`,
};
