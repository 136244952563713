"use client";
import React, { useMemo } from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { GiftCardBalanceCheckerComponentProps } from "~/bff/components/GiftCardBalanceCheckerComponent";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getTestAutomationProps } from "~/helpers/test-automation-props";

import {
  GIFT_CARD_BALANCE_CHECKER_CLASSES,
  giftCardCheckerCountryIframeUrl,
} from "./constants";
import { GiftCardBalanceCheckerContainer } from "./styled";

export const GiftCardBalanceChecker: React.FC<
  GiftCardBalanceCheckerComponentProps
> = ({ title, url, testAutomationId = "gift-card-balance-checker-component" }) => {
  const { locale } = useParams<GlobalPathParams>();

  const iframeUrl = useMemo(() => giftCardCheckerCountryIframeUrl[locale], [locale]);

  return (
    <GiftCardBalanceCheckerContainer
      {...getTestAutomationProps(testAutomationId)}
      id={testAutomationId ?? ""}
    >
      <Container>
        <Typography
          className={GIFT_CARD_BALANCE_CHECKER_CLASSES.title}
          {...getTestAutomationProps("title")}
        >
          {title}
        </Typography>
      </Container>
      <iframe
        src={url || iframeUrl}
        className={GIFT_CARD_BALANCE_CHECKER_CLASSES.iframe}
        scrolling="no"
        title={prepareTextAttribute(title)}
      />
    </GiftCardBalanceCheckerContainer>
  );
};
