export const JSON_LD_CONTEXT = "https://schema.org";

export enum JsonLdType {
  LIST_ITEM = "ListItem",
  ORGANIZATION = "Organization",
  PRODUCT = "Product",
  ITEM_LIST = "ItemList",
  OFFER = "Offer",
  AGGREGATE_OFFER = "AggregateOffer",
  BREAD_CRUMB_LIST = "BreadcrumbList",
  ARTICLE = "Article",
  IMAGE_OBJECT = "ImageObject",
  WEB_PAGE = "WebPage",
  PERSON = "Person",
  OPENING_HOURS_SPECIFICATION = "OpeningHoursSpecification",
}

export enum SchemaOffersUrl {
  IN_STOCK = "https://schema.org/InStock",
  NEW_CONDITION = "https://schema.org/NewCondition",
  ON_SITE_PICK_UP = "https://schema.org/OnSitePickup",
}
